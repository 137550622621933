import { FC, useEffect, useState } from 'react'
import { IEventConfigResponse } from 'types'
import Image from 'next/image'
import { getCFImage, thousandSeparatorNumber } from 'shared/utils'
import { useGetEventNumberOfOpenedBoxQuery } from 'store/api.slice'
import { useRouter } from 'next/router'
import { usePollingEventOpenedBoxes } from 'shared/hook'

const NumberOpenedBoxBanner: FC<{ data?: IEventConfigResponse }> = ({ data }) => {
  const router = useRouter()
  const { query, isReady } = router
  const { data: dataEventOpenedBoxes } = useGetEventNumberOfOpenedBoxQuery(
    { eventCode: query.cid as string },
    {
      skip:
        !isReady ||
        !query ||
        !query.cid ||
        !data ||
        data.number_opened_boxes_setting.radio_display_number_open_box_actions !== 'yes',
    },
  )
  const [prevNumber, setPreveNumber] = useState<number>(-1)

  usePollingEventOpenedBoxes({
    isDisplayOpenedBoxes: data?.number_opened_boxes_setting.radio_display_number_open_box_actions === 'yes',
  })

  useEffect(() => {
    if (!dataEventOpenedBoxes) return
    if (dataEventOpenedBoxes.number_opened_boxes !== prevNumber) {
      setTimeout(() => {
        setPreveNumber(dataEventOpenedBoxes.number_opened_boxes)
      }, 2000)
    }
  }, [dataEventOpenedBoxes])

  if (
    !data ||
    !data.number_opened_boxes_setting ||
    data.number_opened_boxes_setting.radio_display_number_open_box_actions !== 'yes'
  )
    return null
  return (
    <div className="w-full h-[112px] large:h-[286px] mb-3 last:mb-5 relative flex items-center justify-center">
      <Image
        src={getCFImage({
          url:
            data?.number_opened_boxes_setting.image_display_number_open_box_actions ??
            'ad42356c86c1c42ddcffd857f322aa2c',
          isHash: true,
        })}
        alt="background-number-open-boxes"
        layout="fill"
      />
      <div className="relative flex flex-col items-center">
        <p
          className="text-normal large:text-3xl large:py-2"
          style={{ color: data?.number_opened_boxes_setting.color_text_display_number_open_box_actions ?? 'white' }}
        >
          {'Số lượt đã mở quà'}
        </p>
        <div className="overflow-hidden">
          <p
            className={`text-2xl large:text-6xl large:leading-tight text-shadow large:big-text-shadow px-2 large:px-4 font-bold ${
              prevNumber !== -1 &&
              dataEventOpenedBoxes?.number_opened_boxes !== prevNumber &&
              'animate__animated animate__slideInUp'
            }`}
            style={{ color: data?.number_opened_boxes_setting.color_text_display_number_open_box_actions ?? 'white' }}
          >
            {thousandSeparatorNumber({ num: dataEventOpenedBoxes?.number_opened_boxes ?? 0 })}
          </p>
        </div>
      </div>
    </div>
  )
}

export default NumberOpenedBoxBanner
