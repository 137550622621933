import { showToast } from '@spvn/alpha-component-library'
import MysteryBox from 'components/MysteryBox'
import { useRouter } from 'next/router'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useImpressionBoxTracking } from 'shared/hook'
import { micrositeLogin } from 'shared/webBridge'
import { RootState, useAppDispatch } from 'store'
import { useOpenBoxMutation } from 'store/api.slice'
import { DialogType, openDialog } from 'store/dialogs.slice'
import { useLazyGetTasksQuery } from 'store/task-api.slice'
import { IEventConfigResponse } from 'types'

const BoxGrid = ({ data, remainingChances }: { data?: IEventConfigResponse; remainingChances?: number }) => {
  const isLogin = useSelector((state: RootState) => state.app.isLogin)
  const {
    query: { cid, site },
  } = useRouter()
  const [openBox] = useOpenBoxMutation()
  const [getTasks] = useLazyGetTasksQuery()
  const dispatch = useAppDispatch()

  const handleOpenBox = async (boxIdx: number) => {
    if (!isLogin) micrositeLogin(site as string)
    else if (remainingChances === 0) {
      const tasks = await getTasks({ eventCode: cid as string })
      if (tasks.error) {
        const error = tasks.error as { data: { data: { ban_expiry: number } } }
        showToast({
          message: `Bạn đã nhập sai liên tiếp nhiều lần ở nhiệm vụ Nhập mã thêm lượt. Quay lại sau ${Math.ceil(
            (error.data.data.ban_expiry - Date.now()) / 60000,
          )} phút nhé!`,
          iconType: 'warning',
        })
      } else {
        if (!tasks.data?.tasks && !data?.common_event_setting.is_share_task_enabled) {
          showToast({ message: data?.common_event_setting.text_notice_no_quest ?? '', iconType: 'failure' })
        } else {
          if (
            tasks?.data?.tasks?.length === 1 &&
            tasks.data.tasks[0].action_type === 'act_input_code' &&
            !data?.common_event_setting.is_share_task_enabled
          ) {
            dispatch(openDialog({ dialogType: DialogType.RedeemCodeDialog }))
          } else {
            dispatch(openDialog({ dialogType: DialogType.TaskDialog }))
          }
        }
      }
    } else openBox({ boxNo: boxIdx + 1, eventCode: cid as string })
  }

  const { onBoxImpression, onBoxOutImpression } = useImpressionBoxTracking()

  const numberOfBoxes = useMemo(() => {
    return data?.number_of_boxes ?? 0
  }, [data])

  return (
    <>
      {data &&
        Array.from(Array(numberOfBoxes <= 3 ? 1 : 2).keys()).map((rowIdx) => {
          const boxesArr = Array.from(Array(data?.number_of_boxes).keys())
          const breakIdx = numberOfBoxes > 3 ? Math.ceil(data?.number_of_boxes / 2) : numberOfBoxes
          const rowBoxes = rowIdx === 0 ? boxesArr.slice(0, breakIdx) : boxesArr.slice(breakIdx)

          return (
            <div key={rowIdx} className="flex justify-center mx-4 smx:mx-2 mb-2 large:mb-5">
              {rowBoxes.map((boxIdx) => (
                <MysteryBox
                  key={boxIdx}
                  boxIdx={boxIdx}
                  data={data}
                  onOpen={() => handleOpenBox(boxIdx)}
                  onImpress={onBoxImpression}
                  outImpress={onBoxOutImpression}
                />
              ))}
            </div>
          )
        })}
    </>
  )
}

export default memo(BoxGrid)
