import { useRouter } from 'next/router'
import React, { memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getSlotId, sendTrackEvent } from 'shared/utils'
import { micrositeLogin } from 'shared/webBridge'
import { RootState } from 'store'
import { useOpenMilestoneBoxMutation } from 'store/api.slice'
import { IEventConfigResponse, IMilestoneUserStatusResponse } from 'types'

const LoginContent = memo(function LoginContent() {
  const { query } = useRouter()
  const { t } = useTranslation()
  return (
    <p
      onClick={async () => {
        const info = {
          operation: 'click',
          page_section: 'milestone_bar',
          target_type: 'signin_button',
          data: {
            game_activity_id: getSlotId().toString(),
          },
        }
        await sendTrackEvent(info)

        micrositeLogin(query.site as string)
      }}
      className="text-small large:text-large font-bold text-primary"
    >
      {t('Đăng nhập để mở quà thôi!')}
    </p>
  )
})

const HintBarContent = memo(function HintBarContent({
  milestoneSetting,
  milestoneData,
  numOpenedBoxes,
}: {
  milestoneSetting: IEventConfigResponse['milestone_setting']
  milestoneData: IMilestoneUserStatusResponse['milestones']
  numOpenedBoxes: number
}) {
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)
  const { query } = useRouter()
  const [openMilestoneBox] = useOpenMilestoneBoxMutation()
  const clickedRef = useRef(false)

  if (eventStatus === 'up-coming') {
    return (
      <p className="text-small large:text-large text-black">{milestoneSetting.text_notice_upcoming_game_milestone}</p>
    )
  }
  if (eventStatus === 'ended') {
    return <p className="text-small large:text-large text-black">{milestoneSetting.text_notice_end_game_milestone}</p>
  }

  const isReachedAllBoxes = numOpenedBoxes >= milestoneData[milestoneData.length - 1]?.target_achievement
  const isLastBoxRewarded = milestoneData?.[milestoneData.length - 1]?.status === 'REWARDED'

  if (isReachedAllBoxes && isLastBoxRewarded) {
    return (
      <p className="text-small large:text-large text-black">{milestoneSetting.text_notice_complete_all_milestone}</p>
    )
  }

  let hitMilestoneIdx = -1
  milestoneData.forEach((ele, idx) => {
    if (ele.target_achievement === numOpenedBoxes) {
      if (ele.status === 'COMPLETED') {
        hitMilestoneIdx = idx
      } else if (ele.status === 'REWARDED') {
        hitMilestoneIdx = -1
      }
    }
  })

  if (milestoneData?.[milestoneData.length - 1]?.target_achievement <= numOpenedBoxes)
    hitMilestoneIdx = milestoneData.length - 1

  if (hitMilestoneIdx !== -1 || (!isLastBoxRewarded && isReachedAllBoxes)) {
    return (
      <button
        onClick={async () => {
          if (clickedRef.current) return

          clickedRef.current = true

          // send track event
          const info = {
            operation: 'click',
            page_section: 'milestone_bar',
            target_type: 'cta_button',
            data: {
              game_activity_id: getSlotId().toString(),
              milestone_no: hitMilestoneIdx + 1,
            },
          }
          await sendTrackEvent(info)

          await openMilestoneBox({
            event_code: query.cid as string,
            milestone_id: milestoneData[hitMilestoneIdx].milestone_id,
            milestone_no: hitMilestoneIdx + 1,
          })
          clickedRef.current = false
        }}
        className="font-bold text-primary text-small large:text-large"
      >
        {milestoneSetting.text_hit_milestone}
      </button>
    )
  }

  const nextMilestone = milestoneData.find((ele) => ele.target_achievement > numOpenedBoxes)
  const openLeft = (nextMilestone?.target_achievement ?? 0) - numOpenedBoxes
  const content = milestoneSetting.text_not_hit_milestone.split('{{extra_turn}}')
  return (
    <p className="text-small text-black large:text-large">
      {content[0]} <span className="font-bold">{openLeft}</span> {content[1]}
    </p>
  )
})

const HintBar = ({
  milestoneSetting,
  milestoneData,
  numOpenedBoxes,
}: {
  milestoneSetting: IEventConfigResponse['milestone_setting']
  milestoneData: IMilestoneUserStatusResponse['milestones']
  numOpenedBoxes: number
}) => {
  const isLogin = useSelector((state: RootState) => state.app.isLogin)
  return !isLogin ? (
    <LoginContent />
  ) : (
    <HintBarContent milestoneSetting={milestoneSetting} milestoneData={milestoneData} numOpenedBoxes={numOpenedBoxes} />
  )
}

export default HintBar
