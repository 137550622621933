import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IEventConfigResponse } from 'types'
import ArrowRightIcon from 'icons/ArrowRight'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import clsx from 'clsx'
import { getSlotId, sendTrackEvent, simpleHash } from 'shared/utils'
import { useRouter } from 'next/router'
import { getPersistentNextGameReminderHash, setPersistentNextGameReminderHash } from 'shared/persistent'
import { micrositeReminder } from 'shared/webBridge'

const ReminderBar = ({ data }: { data?: IEventConfigResponse }) => {
  const { t } = useTranslation()
  const { query } = useRouter()
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)

  const reminderData = useMemo(() => {
    return {
      redirectPath: data?.reminder_next_game_setting.url_reminder_next_game ?? '',
      title: data?.reminder_next_game_setting.text_reminder_next_game_title ?? '',
      content: data?.reminder_next_game_setting.text_reminder_next_game_content ?? '',
      eventCode: query.cid,
    }
  }, [data, query, query.cid, query.site])

  const reminderHash = useMemo(() => {
    return simpleHash(JSON.stringify(reminderData))
  }, [reminderData])

  const storageReminderHash = getPersistentNextGameReminderHash()

  const [isReminded, setIsReminded] = useState(() => {
    return storageReminderHash === reminderHash
  })

  useEffect(() => {
    setIsReminded(storageReminderHash === reminderHash)
  }, [reminderHash, storageReminderHash])

  const handleRemind = async () => {
    const info = {
      operation: 'click',
      target_type: 'remind_btn',
      data: {
        game_activity_id: getSlotId().toString(),
      },
    }
    await sendTrackEvent(info)
    await micrositeReminder({
      ...reminderData,
      reminderId: data?.reminder_next_game_setting.time_reminder_next_game as number,
      startTime: Math.round((data?.reminder_next_game_setting.time_reminder_next_game ?? 0) / 1000) as number,
      endTime: Math.round(
        ((data?.reminder_next_game_setting.time_reminder_next_game ?? 0) + 60 * 60 * 1000) / 1000,
      ) as number,
    })

    if (!document.referrer || document.referrer.startsWith(process.env.BASE_URL as string)) {
      setIsReminded(true)
      setPersistentNextGameReminderHash(reminderHash)
    }
  }

  const isHideReminder = useMemo(() => {
    // UpComming || No Next session
    return eventStatus === 'up-coming' || data?.reminder_next_game_setting.radio_reminder_next_game !== 'yes'
  }, [data, eventStatus])

  const isHideTimer = useMemo(() => {
    return eventStatus === 'ended' || !eventStatus
  }, [eventStatus])

  if (isHideReminder) return null

  return (
    <div
      className={clsx(
        'w-1/2 flex-col items-center text-small py-0.5 smx:px-1 px-1.5 large:py-1 flex',
        !isHideTimer && 'border-l border-line',
      )}
    >
      <p
        className="mb-1 large:mb-2 large:text-normal text-center"
        style={{ color: data?.reminder_next_game_setting.color_reminder_next_game_notice }}
      >
        {t('Phiên tới sắp diễn ra')}
      </p>
      <div className="flex items-center">
        {isReminded ? (
          <p
            className="font-medium large:text-large"
            style={{ color: data?.reminder_next_game_setting.color_clicked_reminder_next_game_button }}
          >
            {t('Đã bật nhắc nhở')}
          </p>
        ) : (
          <>
            <p
              className="font-medium mr-2 large:mr-2.5 large:text-large cursor-pointer"
              style={{ color: data?.reminder_next_game_setting.color_reminder_next_game_button }}
              onClick={handleRemind}
            >
              {t('Nhắc tôi')}
            </p>
            <ArrowRightIcon color={data?.reminder_next_game_setting.color_reminder_next_game_button} />
          </>
        )}
      </div>
    </div>
  )
}

export default React.memo(ReminderBar)
