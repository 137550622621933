const RewardIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.20252 5.97146V4.73038C9.20252 4.04495 8.62871 3.4893 7.92087 3.4893C7.21304 3.4893 6.63923 4.04495 6.63923 4.73038C6.63923 5.41581 7.21304 5.97146 7.92087 5.97146H9.20252ZM9.97151 2.85638C9.45743 2.32908 8.72884 2 7.92087 2C6.36364 2 5.10126 3.22243 5.10126 4.73038C5.10126 5.39045 5.34314 5.99581 5.74575 6.46789H1.76899C1.34429 6.46789 1 6.80128 1 7.21254C1 7.6238 1.34429 7.95719 1.76899 7.95719H3V17.2875C3 17.6987 3.34429 18.0321 3.76899 18.0321H9.15186H10.6898H16.0728C16.4975 18.0321 16.8417 17.6987 16.8417 17.2875V12.7093H16.8418V11.22H16.8417V7.95719H18.231C18.6557 7.95719 19 7.6238 19 7.21254C19 6.80128 18.6557 6.46789 18.231 6.46789H14.1973C14.5999 5.99581 14.8417 5.39045 14.8417 4.73038C14.8417 3.22243 13.5794 2 12.0221 2C11.2142 2 10.4856 2.32908 9.97151 2.85638ZM15.3038 7.95719H10.6898V11.22H15.3038V7.95719ZM15.3038 12.7093H10.6898V16.5428H15.3038V12.7093ZM9.15186 16.5428V12.7093H4.53797V16.5428H9.15186ZM4.53797 11.22H9.15186V7.95719H4.53797V11.22ZM10.7405 4.73038L10.7405 5.97146H12.0221C12.73 5.97146 13.3038 5.41581 13.3038 4.73038C13.3038 4.04495 12.73 3.4893 12.0221 3.4893C11.3143 3.4893 10.7405 4.04495 10.7405 4.73038Z"
        fill="white"
      />
    </svg>
  )
}

export default RewardIcon
