import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDialogs } from 'shared/hook'
import { DialogType } from 'store/dialogs.slice'
import { IPerformBatchTasksResponse } from 'types'

const RedeemCodeStatus: FC<{
  totalBalance: number
  guidanceInputText: string
  submitCodesDataResponseData?: IPerformBatchTasksResponse
}> = ({ totalBalance, guidanceInputText, submitCodesDataResponseData }) => {
  const [prevSubmitCodeData, setPrevSubmitCodeData] = useState<IPerformBatchTasksResponse | undefined>(undefined)

  const { isOpen: isRedeemCodeDialogOpen } = useDialogs(DialogType.RedeemCodeDialog)

  useEffect(() => {
    if (!isRedeemCodeDialogOpen) {
      setPrevSubmitCodeData(undefined)
    }
  }, [isRedeemCodeDialogOpen])

  const tempsubmitCodesDataResponseData = useMemo(() => {
    if (!submitCodesDataResponseData) return prevSubmitCodeData
    setPrevSubmitCodeData(submitCodesDataResponseData)
    return submitCodesDataResponseData
  }, [submitCodesDataResponseData, prevSubmitCodeData])

  const numberOfNoExistCode = useMemo(() => {
    return tempsubmitCodesDataResponseData?.results.filter((ele) => ele.code === 3027).length ?? 0
  }, [tempsubmitCodesDataResponseData])

  const numberOfUsedCode = useMemo(() => {
    return tempsubmitCodesDataResponseData?.results.filter((ele) => ele.code === 4040).length ?? 0
  }, [tempsubmitCodesDataResponseData])

  const numberOfSuccessCode = useMemo(() => {
    return tempsubmitCodesDataResponseData?.results.filter((ele) => ele.code === 0).length ?? 0
  }, [tempsubmitCodesDataResponseData])

  const numberOfChancesReceived = useMemo(() => {
    return tempsubmitCodesDataResponseData?.prize_awarded?.[0].quantity ?? 0
  }, [tempsubmitCodesDataResponseData])

  const remainingAttempts = useMemo(() => {
    if (
      tempsubmitCodesDataResponseData?.max_allowed_attempts === 0 &&
      tempsubmitCodesDataResponseData.current_wrong_attempts === 0
    )
      return -1
    return (
      (tempsubmitCodesDataResponseData?.max_allowed_attempts ?? 0) -
      (tempsubmitCodesDataResponseData?.current_wrong_attempts ?? 0)
    )
  }, [tempsubmitCodesDataResponseData])

  if (remainingAttempts === 0 && tempsubmitCodesDataResponseData) {
    return (
      <div className="text-center font-normal text-normal">
        <p className="font-medium mb-1 text-large">
          Bạn đã nhập sai mã {tempsubmitCodesDataResponseData?.max_allowed_attempts ?? 0} lần liên tiếp
        </p>
        <p>
          Hãy thử lại sau{' '}
          <span className="font-bold">
            {Math.ceil((tempsubmitCodesDataResponseData.ban_duration_second ?? 0) / 60)} phút
          </span>{' '}
          nha!
        </p>
      </div>
    )
  }

  if (numberOfNoExistCode > 0) {
    return (
      <div className="text-center font-normal text-normal">
        <p className="font-medium mb-1 text-large">
          {numberOfSuccessCode > 0
            ? `Chúc mừng bạn đã nhận được ${numberOfChancesReceived} lượt!`
            : `Ui! Có sai sót khi nhập mã!`}
        </p>
        <p>
          Bạn còn{' '}
          <span className="font-bold">
            {remainingAttempts}/{tempsubmitCodesDataResponseData?.max_allowed_attempts ?? 0}
          </span>{' '}
          lần để thử lại. Sửa sai thôi!
        </p>
      </div>
    )
  }

  if (numberOfUsedCode > 0) {
    return (
      <div className="text-center font-normal text-normal">
        <p className="font-medium mb-1 text-large">
          {numberOfSuccessCode > 0
            ? `Chúc mừng bạn đã nhận được ${numberOfChancesReceived} lượt!`
            : `Ui! Có sai sót khi nhập mã!`}
        </p>
        <p>
          Bạn đã nhập <span className="font-bold">{numberOfUsedCode}</span> mã đã sử dụng. Thử lại nha!
        </p>
      </div>
    )
  }

  return (
    <div className="text-center font-normal text-normal">
      <p className="mb-1">
        Bạn có <span className="font-bold mb-1">{totalBalance}</span> lượt mở.
      </p>
      <p>{guidanceInputText}</p>
    </div>
  )
}

export default React.memo(RedeemCodeStatus)
