import React from 'react'

const ArrowRightIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.29293 6.00004L0.146484 0.853591L0.853591 0.146484L6.00004 5.29293C6.39056 5.68345 6.39056 6.31662 6.00004 6.70714L0.853591 11.8536L0.146484 11.1465L5.29293 6.00004Z"
        fill={color ?? '#000'}
      />
    </svg>
  )
}

export default React.memo(ArrowRightIcon)
