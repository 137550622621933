import { ChangeEvent, FC, useRef, useState, memo, useMemo, useCallback, useEffect } from 'react'
import { getImage, isLargeScreen } from 'shared/utils'

const MAX_INPUT_NUMBER = 15

const InputNote: FC<{ statusCode?: number; handleOnClick: () => void }> = memo(function InputNote({
  statusCode,
  handleOnClick,
}) {
  const errorMessage = useMemo(() => {
    switch (statusCode) {
      case 4027:
        return 'Có lỗi xử lý'
      case 4040:
        return 'Đã sử dụng'
      default:
        return 'Không tồn tại'
    }
  }, [statusCode])

  return (
    <div className="top-1/2 -translate-y-1/2 right-2 absolute flex items-center" onClick={handleOnClick}>
      <img
        src={getImage(statusCode === 0 ? 'success-code.png' : 'failed-code.png')}
        width={14}
        height={14}
        alt="input-note-img"
        className="w-[14px] h-[14px] mr-1"
      />
      {statusCode === 0 ? (
        <p className="text-success text-foot">Đã nhận lượt</p>
      ) : (
        <p className="text-errorinput text-foot">{errorMessage}</p>
      )}
    </div>
  )
})

const RedeemCodeInput: FC<{
  value: string
  onChange: (e: ChangeEvent<any>) => void
  onBlur: (e: ChangeEvent<any>) => void
  resetFieldValue: () => void
  resetErrorAndTouched: () => void
  name: string
  isReleaseFormNote: boolean
  disabled?: boolean
  touched?: boolean
  error?: 'used' | 'noexist'
  submitResult?: { code: number; msg: string }
}> = ({
  value,
  onChange,
  onBlur,
  resetFieldValue,
  resetErrorAndTouched,
  name,
  isReleaseFormNote,
  disabled,
  touched,
  error,
  submitResult,
}) => {
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false)
  const [isResetError, setIsResetError] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const isShowSubmitNote = useMemo(() => {
    return (touched && error) || (submitResult && !isResetError && !isReleaseFormNote)
  }, [error, touched, value, submitResult, isResetError, isReleaseFormNote])

  const resetField = useCallback(() => {
    resetFieldValue()
    resetErrorAndTouched()
    inputRef.current?.focus()
  }, [resetFieldValue, resetErrorAndTouched, inputRef, inputRef.current])

  // console.log({ name, submitResult }, 'submitResult')
  useEffect(() => {
    // console.log({ name, submitResult }, 'subnot')
    setIsResetError(false)
  }, [submitResult])
  return (
    <div className="w-full my-2 relative">
      <input
        id={name}
        ref={inputRef}
        name={name}
        disabled={disabled}
        className="w-full border border-line h-9 px-2 py-2.5 text-normal outline-primary rounded-sm disabled:bg-[#F5F5F5]"
        type="text"
        placeholder="Nhập mã"
        onChange={(e) => {
          e.target.value = e.target.value.slice(0, MAX_INPUT_NUMBER)
          e.target.value = e.target.value.replace(' ', '')
          // e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/gi, '')
          if (e.target.value !== '') setIsShowDelete(true)
          else {
            resetField()
            setIsShowDelete(false)
          }
          onChange(e)
        }}
        onBlur={(e) => {
          setTimeout(
            () => {
              setIsShowDelete(false)
              onBlur(e)
            },
            isLargeScreen() ? 900 : 0,
          ) //For handle concurrently onBlur with onClick
        }}
        onFocus={(e) => {
          if (e.target.value === '' || !!error) return
          setIsShowDelete(true)
          setIsResetError(true)
        }}
        value={value}
      />
      {isShowSubmitNote && (
        <InputNote
          statusCode={submitResult?.code}
          handleOnClick={() => {
            if (submitResult?.code === 0) return
            resetField()
            setIsResetError(true)
          }}
        />
      )}
      {isShowDelete && !isShowSubmitNote && (
        <img
          src={getImage('ic-close-circle.png')}
          width={16}
          height={16}
          alt="clear-input-img"
          className="w-4 h-4 rounded-full absolute top-1/2 -translate-y-1/2 right-2"
          onClick={() => {
            resetField()
            setIsShowDelete(false)
          }}
        />
      )}
    </div>
  )
}

export default RedeemCodeInput
