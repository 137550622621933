import { GeneralDialog } from '@spvn/alpha-component-library'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDialogs } from 'shared/hook'
import { getImage } from 'shared/utils'
import { DialogType } from 'store/dialogs.slice'

export interface ErrorPopupData {
  actionDisable: boolean
}

const ErrorPopup = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { isOpen, close, data } = useDialogs(DialogType.ErrorPopup)
  const { actionDisable } = (data || {}) as ErrorPopupData

  return (
    <GeneralDialog
      onClose={() => {
        router.reload()
        close()
      }}
      open={isOpen}
      hideSecondaryAction
      primaryActionProps={{ disabled: actionDisable }}
      primaryActionText={t('Tải lại')}
    >
      <div className="text-center p-3">
        <Image src={getImage('network-error.png')} width={125} height={125} alt="" />
        <p className="font-medium text-normal mb-1">{t('Ui! Có gì đó không ổn!')}</p>
        <p className="text-normal">{t('Bạn tải lại lần nữa nha!')}</p>
      </div>
    </GeneralDialog>
  )
}

export default ErrorPopup
