import clsx from 'clsx'
import CountDownBar from 'components/CountDownBar'
import ReminderBar from 'components/ReminderBar'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { IEventConfigResponse } from 'types'

const InfoBar = ({ data }: { data?: IEventConfigResponse }) => {
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)

  const isInfoBarEmpty = eventStatus === 'ended' && data?.reminder_next_game_setting.radio_reminder_next_game === 'no'

  return (
    <div
      className={clsx(
        'flex w-full flex-grow large:max-w-[600px] mx-auto mb-4 large:mb-7 justify-center',
        isInfoBarEmpty && 'h-[10px] large:h-[10px]',
      )}
    >
      <CountDownBar data={data} />
      <ReminderBar data={data} />
    </div>
  )
}

export default React.memo(InfoBar)
