import 'animate.css'
import clsx from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSlotId, isLargeScreen, sendTrackEvent } from 'shared/utils'
import { RootState } from 'store'
import { useOpenMilestoneBoxMutation } from 'store/api.slice'
import styles from './MilestoneBox.module.scss'

interface MilestoneBoxProps {
  title: string
  translateX: number
  isReached: boolean
  isReceived: boolean
  boxImgIncomplete: string
  boxImgComplete: string
  boxImgReceive: string
  boxWidth: number
  milestoneId: number
  pos: number
}

const getTitleText = ({ isReached, isReceived, title }: { isReached: boolean; isReceived: boolean; title: string }) => {
  if (isReceived) {
    return 'Đã nhận'
  } else if (isReached) {
    return 'Nhận ngay'
  } else return title
}

const MilestoneBox: React.FC<MilestoneBoxProps> = ({
  title,
  translateX,
  isReached,
  isReceived,
  boxImgIncomplete,
  boxImgComplete,
  boxImgReceive,
  boxWidth,
  milestoneId,
  pos,
}) => {
  const { query } = useRouter()
  const [openMilestoneBox] = useOpenMilestoneBoxMutation()
  const clickedRef = useRef(false)
  const upperOpenedBoxId = useSelector((state: RootState) => state.app.boxIdxOpened)

  const getMilestoneImage = useCallback(
    ({ isReached, isReceived }: { isReached: boolean; isReceived: boolean }) => {
      if (isReached) return boxImgComplete
      else if (isReceived) return boxImgReceive
      else return boxImgIncomplete
    },
    [boxImgIncomplete, boxImgComplete, boxImgReceive],
  )
  return (
    <button
      id={`spvn-milestone-box-no-${pos}`}
      disabled={upperOpenedBoxId !== null}
      className="flex flex-col items-center absolute top-6 cursor-pointer transition-transform motion-reduce:transition-none"
      style={{ transform: `translate(${translateX}px, -${boxWidth / (isLargeScreen() ? 8 : 4)}px)` }}
      onClick={async () => {
        if (!isReached || isReceived) return
        if (clickedRef.current) return

        clickedRef.current = true

        // send track event
        const info = {
          operation: 'click',
          page_section: 'milestone_bar',
          target_type: 'milestone_bar',
          data: {
            game_activity_id: getSlotId().toString(),
            milestone_no: pos + 1,
          },
        }
        await sendTrackEvent(info)

        await openMilestoneBox({ event_code: query.cid as string, milestone_id: milestoneId, milestone_no: pos + 1 })
        clickedRef.current = false
      }}
    >
      <div style={{ width: boxWidth, height: boxWidth }} className={styles['milestone-box-wrapper']}>
        <Image
          draggable={false}
          src={`https://cf.shopee.vn/file/${getMilestoneImage({ isReached, isReceived })}`}
          width={boxWidth}
          height={boxWidth}
          alt="present-box"
          className={clsx(isReached && 'animate__animated animate__tada animate__slow animate__infinite')}
        />
      </div>
      <p
        className={clsx(
          'text-foot large:text-normal absolute -bottom-4 large:-bottom-5 min-w-[60px] large:min-w-[80px] text-center',
          !isReached && !isReceived && 'opacity-40',
          isReached && 'font-bold text-primary',
        )}
      >
        {getTitleText({ isReached, isReceived, title })}
      </p>
    </button>
  )
}

export default MilestoneBox
