import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { IEventConfigResponse } from 'types'
import { CountDownTimer } from '@spvn/alpha-component-library'
import { useTranslation } from 'react-i18next'
import { useMemo, memo } from 'react'

const CountDownBar = ({ data }: { data?: IEventConfigResponse }) => {
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)
  const { t } = useTranslation()

  const timeLeft = useMemo(() => {
    if (!data) return 0
    if (eventStatus === 'on-going') return (data.end_time - Date.now()) / 1000
    else if (eventStatus === 'up-coming') return (data.begin_time - Date.now()) / 1000
    else return 0
  }, [data, eventStatus])

  if (eventStatus === 'ended' || !eventStatus) return null

  return (
    <div className={clsx('flex flex-col w-1/2 items-center py-0.5 large:py-1')}>
      <p
        className="text-foot font-normal mb-1 large:mb-2.5 large:text-normal"
        style={{ color: data?.color_countdown_timer ?? '#000000' }}
      >
        {eventStatus === 'up-coming' ? t('BẮT ĐẦU TRONG') : t('KẾT THÚC TRONG')}
      </p>
      <CountDownTimer
        interval={timeLeft}
        separatorStyle={{ color: data?.color_countdown_timer ?? '#000' }}
        timerStyle={{ color: 'white', backgroundColor: data?.color_countdown_timer ?? '#000000' }}
        dateSeparatorString={t('Ngày')}
        dateSeparatorStyle={{ color: data?.color_countdown_timer ?? '#000' }}
      />
    </div>
  )
}

export default memo(CountDownBar)
