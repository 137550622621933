import { GeneralDialog, showToast } from '@spvn/alpha-component-library'
import { useFormik } from 'formik'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useDialogs, useEventStatusCalculation, useImpressionYomostInputCodeDialogTracking } from 'shared/hook'
import { eventStatusToInt, getSlotId, requestTimeout, sendTrackEvent } from 'shared/utils'
import { useGetEventConfigQuery, useGetUserChanceQuery } from 'store/api.slice'
import { DialogType } from 'store/dialogs.slice'
import { useGetTasksQuery, usePerformBatchTasksMutation } from 'store/task-api.slice'
import * as Yup from 'yup'
import RedeemCodeDialogHeader from './RedeemCodeDialogHeader'
import RedeemCodeInput from './RedeemCodeInput'
import RedeemCodeStatus from './RedeemCodeStatus'

const RedeemCodeDialog = () => {
  const { query } = useRouter()

  /// Dialog management ///
  const { isOpen: isTaskDialogOpen } = useDialogs(DialogType.TaskDialog)
  const { isOpen, close, taskId: redeemTaskId } = useDialogs(DialogType.RedeemCodeDialog)
  const { open: openClaimPrizeDialog } = useDialogs(DialogType.ClaimPrizeYomostDialog)

  /// RTK Query part ///
  const { data } = useGetEventConfigQuery(query.cid as string, {
    skip: !query.cid,
    refetchOnFocus: true,
  })
  const { eventStatus } = useEventStatusCalculation(data)
  const { data: chancesData } = useGetUserChanceQuery(
    {
      eventCode: query.cid as string,
    },
    { skip: !query.cid || eventStatus !== 'on-going', refetchOnFocus: true },
  )
  const { data: tasksData } = useGetTasksQuery(
    { eventCode: query.cid as string },
    {
      skip: !query.cid || (!isTaskDialogOpen && !isOpen),
    },
  )
  const [submitCodes, submitCodesMeta] = usePerformBatchTasksMutation()

  /// Hooks ///
  const [isReadySubmit, setReadySubmit] = useState<boolean>(true)
  const [isReleaseFormNote, setReleaseFormNote] = useState<boolean>(false)
  const [successFields, setSuccessFields] = useState<number[]>([])
  const [prevSubmitCode, setPrevSubmitCode] = useState<Record<string, string>>({})
  const [isEverSubmitted, setEverSubmitted] = useState<boolean>(false)

  /// Main logic for create input fields ///
  const redeemCodeData = data?.redemption_code_setting
  const fieldNames = Array.from(Array(redeemCodeData?.number_maximum_field_input_code ?? 0).keys(), (x) => 'code_' + x)
  const initialValues = fieldNames.reduce((pre, cur) => ({ ...pre, [cur]: '' }), {})

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape(
      fieldNames.reduce(
        (pre, cur) => ({
          ...pre,
          [cur]: Yup.string().max(15, 'noexist'),
        }),
        {},
      ),
    ),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const requestsData = Object.entries(values)
        .filter((field, idx) => {
          if (submitCodeResult?.[idx]?.code === 0) return false
          return field[1] !== ''
        })
        .map((fieldName) => ({
          event_code: query.cid as string,
          claim_reward: true,
          execute_data: {
            gift_code: (fieldName[1] as string).toLowerCase(),
          },
          task_id:
            (redeemTaskId as number) ??
            tasksData?.tasks.find((task) => task.action_type === 'act_input_code')?.id ??
            -1,
        }))
      submitCodes({ requests: requestsData })
    },
  })

  const handleCloseDialog = () => {
    // Send tracking
    const error = submitCodesMeta.error as { data: { code: number } } | undefined
    const info = {
      operation: 'click',
      page_section: 'input_code_popup',
      target_type: 'close_button',
      data: {
        game_activity_id: `${getSlotId()}`,
        game_slot_id: `${getSlotId()}`,
        status: eventStatusToInt(eventStatus),
        pop_up_status: isEverSubmitted ? 1 : 0,
        remaining_chances: chancesData?.total_balance ?? -2,
        max_input_field: fieldNames.length,
        total_input_code: Object.entries(formik.values).filter((field, idx) => {
          if (submitCodeResult?.[idx]?.code === 0) return false
          return field[1] !== ''
        }).length,
        total_valid_codes: submitCodesMeta.data?.results.filter((ele) => ele.code === 0).length ?? -1,
        total_not_existing_codes: submitCodesMeta.data?.results.filter((ele) => ele.code === 3027).length ?? -1,
        total_used_codes: submitCodesMeta.data?.results.filter((ele) => ele.code === 4040).length ?? -1,
        remaining_input_times:
          (submitCodesMeta.data?.max_allowed_attempts ?? 0) - (submitCodesMeta.data?.current_wrong_attempts ?? 1),
        is_blocked_user:
          !error && !submitCodesMeta.data
            ? -1
            : error?.data.code === 4006 ||
              (submitCodesMeta.data?.current_wrong_attempts ?? 0) === (submitCodesMeta.data?.max_allowed_attempts ?? 1)
            ? 1
            : 0,
      },
    }
    sendTrackEvent(info)

    formik.resetForm()
    setReleaseFormNote(true)
    setSuccessFields([])
    setEverSubmitted(false)
    submitCodesMeta.reset()
    close()
  }

  useEffect(() => {
    // Handle failed or banned user
    if (submitCodesMeta.error) {
      const error = submitCodesMeta.error as { data: { data: { ban_expiry: number } } }
      showToast({
        message: `Bạn đã nhập sai liên tiếp nhiều lần ở nhiệm vụ Nhập mã thêm lượt. Quay lại sau ${Math.ceil(
          (error.data.data.ban_expiry - Date.now()) / 60000,
        )} phút nhé!`,
        iconType: 'warning',
      })
    }
  }, [submitCodesMeta.error])

  const submitCodeResult = useMemo(() => {
    // Handle failed or banned user
    if (submitCodesMeta.error) {
      const error = submitCodesMeta.error as { data: { code: number } }
      // Send tracking
      const info = {
        operation: 'click',
        page_section: 'input_code_popup',
        target_type: 'confirm_button',
        data: {
          game_activity_id: `${getSlotId()}`,
          game_slot_id: `${getSlotId()}`,
          status: eventStatusToInt(eventStatus),
          pop_up_status: isEverSubmitted ? 1 : 0,
          remaining_chances: chancesData?.total_balance ?? -2,
          max_input_field: fieldNames.length,
          total_input_code: Object.entries(formik.values).filter((field, idx) => {
            if (submitCodeResult?.[idx]?.code === 0) return false
            return field[1] !== ''
          }).length,
          total_valid_codes: -2,
          total_not_existing_codes: -2,
          total_used_codes: -2,
          remaining_input_times: -2,
          is_blocked_user: error.data.code === 4006 ? 1 : 0,
        },
      }
      sendTrackEvent(info)
    }
    if (!submitCodesMeta.data) return

    let idx = 0
    const res = Object.entries(formik.values).map((ele, ind) => {
      if (ele[1] === '') return undefined
      if (successFields.includes(ind)) return { code: 0, msg: 'success-fake' }
      return submitCodesMeta.data?.results?.[idx++]
    })

    // Find successed field
    idx = 0
    const listSuccess: number[] = [...successFields]
    Object.entries(formik.values).forEach((ele, ind) => {
      if (ele[1] === '') return
      if (successFields.includes(ind)) return
      if (submitCodesMeta.data?.results?.[idx]?.code === 0) {
        listSuccess.push(ind)
      }
      idx++
    })
    setSuccessFields(listSuccess)

    if (submitCodesMeta.data?.results?.every((ele) => ele.code === 0)) {
      // All code submitted are success
      handleCloseDialog()
      openClaimPrizeDialog({ totalChance: submitCodesMeta.data.prize_awarded[0].quantity })
    }

    const is_blocked_user =
      submitCodesMeta.data.max_allowed_attempts > 0 &&
      submitCodesMeta.data.current_wrong_attempts === submitCodesMeta.data.max_allowed_attempts
    // send tracking
    const info = {
      operation: 'click',
      page_section: 'input_code_popup',
      target_type: 'confirm_button',
      data: {
        game_activity_id: `${getSlotId()}`,
        game_slot_id: `${getSlotId()}`,
        status: eventStatusToInt(eventStatus),
        pop_up_status: isEverSubmitted ? 1 : 0,
        remaining_chances: chancesData?.total_balance ?? -2,
        max_input_field: fieldNames.length,
        total_input_code: Object.entries(formik.values).filter((field, idx) => {
          if (submitCodeResult?.[idx]?.code === 0) return false
          return field[1] !== ''
        }).length,
        total_valid_codes: submitCodesMeta.data.results.filter((ele) => ele.code === 0).length,
        total_not_existing_codes: submitCodesMeta.data.results.filter((ele) => ele.code === 3027).length,
        total_used_codes: submitCodesMeta.data.results.filter((ele) => ele.code === 4040).length,
        remaining_input_times:
          submitCodesMeta.data.max_allowed_attempts > 0
            ? submitCodesMeta.data.max_allowed_attempts - submitCodesMeta.data.current_wrong_attempts
            : -1,
        is_blocked_user: is_blocked_user ? 1 : 0,
      },
    }
    sendTrackEvent(info)
    if (!isEverSubmitted) setEverSubmitted(true)

    return res
  }, [submitCodesMeta])

  const isAllFieldsEmpty = useMemo(() => {
    return Object.values(formik.values).every((x) => x === null || x === '' || x === undefined)
  }, [formik.values])

  const areFieldsModified = useMemo(() => {
    for (const key in formik.values) {
      if (formik.values[key] && formik.values[key] !== prevSubmitCode[key]) return true
    }
    return false
  }, [formik.values, submitCodesMeta])

  const isDisabledSubmit = useMemo(() => {
    return (
      isAllFieldsEmpty ||
      !areFieldsModified ||
      !isReadySubmit ||
      submitCodesMeta.isLoading ||
      (submitCodesMeta.data &&
        submitCodesMeta.data?.current_wrong_attempts === submitCodesMeta.data?.max_allowed_attempts)
    )
  }, [isAllFieldsEmpty, areFieldsModified, isReadySubmit, submitCodesMeta.isLoading, submitCodesMeta.data])

  useImpressionYomostInputCodeDialogTracking({
    isDialogOpen: isOpen,
    eventStatus: eventStatusToInt(eventStatus),
    isEverSubmitted,
    chancesData,
    max_input_field: fieldNames.length,
  })

  if (!redeemCodeData) return null

  return (
    <GeneralDialog
      onClose={handleCloseDialog}
      open={isOpen}
      showCrossClose
      hideSecondaryAction
      primaryActionText="Xác nhận"
      primaryActionProps={{
        type: 'submit',
        disabled: isDisabledSubmit,
        style: {
          ...(isDisabledSubmit && { color: 'rgba(0, 0, 0, 0.4)' }),
          lineHeight: '20px',
          fontSize: '16px',
          paddingTop: '14px',
          paddingBottom: '14px',
        },
      }}
      onClickPrimary={() => {
        setReleaseFormNote(false)
        formik.handleSubmit()
        setPrevSubmitCode(formik.values)
        setReadySubmit(false)
        requestTimeout(() => {
          setReadySubmit(true)
        }, 1000)
      }}
    >
      <RedeemCodeDialogHeader headerImgHash={redeemCodeData.image_header_quest_input_code} />
      <div className="my-3 px-5">
        <RedeemCodeStatus
          totalBalance={chancesData?.total_balance ?? 0}
          guidanceInputText={redeemCodeData.text_guidance_get_code_input_code}
          submitCodesDataResponseData={submitCodesMeta.data}
        />
        <div id="spvn-redeem-code-zone">
          <form onSubmit={formik.handleSubmit}>
            {fieldNames.map((name, idx) => {
              return (
                <RedeemCodeInput
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  resetFieldValue={() => {
                    formik.setFieldValue(name, '')
                  }}
                  resetErrorAndTouched={() => {
                    const newTouchedState = formik.touched
                    delete newTouchedState[name]
                    formik.setTouched(newTouchedState)
                    const newErrorState = formik.errors
                    delete newErrorState[name]
                    formik.setErrors(newErrorState)
                  }}
                  key={idx}
                  name={name}
                  value={formik.values[name]}
                  touched={formik.touched[name]}
                  error={formik.errors[name]}
                  submitResult={submitCodeResult?.[idx]}
                  disabled={submitCodeResult?.[idx]?.code === 0 && !isReleaseFormNote}
                  isReleaseFormNote={isReleaseFormNote}
                />
              )
            })}
          </form>
        </div>
        <p className="text-center text-foot text-note">
          Bạn có thể nhập tối đa {redeemCodeData.number_maximum_field_input_code} mã mỗi lần.
        </p>
      </div>
    </GeneralDialog>
  )
}

export default RedeemCodeDialog
