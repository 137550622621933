import { FC } from 'react'
import { getCFImage } from 'shared/utils'
import Image from 'next/image'

const RedeemCodeDialogHeader: FC<{ headerImgHash?: string }> = ({ headerImgHash }) => {
  return (
    <div className="w-full h-[45px] large:h-[60px] relative bg-cover bg-center flex items-center justify-center">
      <Image
        src={getCFImage({ url: headerImgHash ?? '6970ca15d7c866b23ac7c15ce5660b1d', isHash: true })}
        alt="input-code-header-img"
        layout="fill"
        draggable={false}
      />
      <p className="z-10 font-bold text-normal text-white large:text-xl">NHẬP MÃ KIẾM LƯỢT</p>
    </div>
  )
}

export default RedeemCodeDialogHeader
