import { GeneralDialog } from '@spvn/alpha-component-library'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IAxiosBaseQueryError } from 'shared/axios-settings'
import { useDialogs, useGetConfigErrorHanlder, useImpressionYomostClaimPrizeDialogTracking } from 'shared/hook'
import { eventStatusToInt, getCFImage, getSlotId, requestTimeout, sendTrackEvent } from 'shared/utils'
import { RootState } from 'store'
import { useGetEventConfigQuery } from 'store/api.slice'
import { DialogType } from 'store/dialogs.slice'
import { ITimer } from 'types'

const ClaimPrizeDialog = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { query } = router

  const { isOpen, close, totalChance = 0 } = useDialogs(DialogType.ClaimPrizeYomostDialog)
  const [isOpenProtectLayer, setOpenProtectLayer] = useState(isOpen)

  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)

  useImpressionYomostClaimPrizeDialogTracking({
    isDialogOpen: isOpen,
    chance_earned: totalChance as number,
    eventStatus: eventStatusToInt(eventStatus),
  })

  useEffect(() => {
    let timer: ITimer
    if (isOpen) {
      setOpenProtectLayer(true)
      timer = requestTimeout(() => {
        setOpenProtectLayer(false)
      }, 1000) //Handle user multiclick
    }
    return () => {
      timer?.clear()
    }
  }, [isOpen])

  const { data, error } = useGetEventConfigQuery(query.cid as string, {
    skip: !query.cid,
  })

  const clickPrimaryButtonRef = useRef(false)

  useGetConfigErrorHanlder(error as IAxiosBaseQueryError)

  const handleCloseDialog = () => {
    clickPrimaryButtonRef.current = false
    close()
  }

  const handleOnClickSecondary = () => {
    if (clickPrimaryButtonRef.current) return
    clickPrimaryButtonRef.current = true

    // Send track
    const info = {
      operation: 'click',
      page_section: 'success_popup',
      target_type: 'cta_button',
      data: {
        game_activity_id: `${getSlotId()}`,
        game_slot_id: `${getSlotId()}`,
        status: eventStatusToInt(eventStatus),
        chance_earned: totalChance,
      },
    }
    sendTrackEvent(info)
    handleCloseDialog()
  }

  return (
    <>
      <GeneralDialog
        onClose={handleCloseDialog}
        open={isOpen}
        hidePrimaryAction
        secondaryActionProps={{ style: { color: '#EE4D2D', fontSize: '16px', lineHeight: '20px', padding: '14px' } }}
        secondaryActionText={t('Mở quà ngay')}
        onClickSecondary={handleOnClickSecondary}
        backdropClickable={true}
      >
        <div className="py-4 px-5 large:p-5 flex justify-center items-center flex-col text-center large:w-[470px]">
          <div className="relative h-[120px] large:h-[160px] w-[120px] large:w-[160px] mb-8 large:mb-5">
            <Image
              src={getCFImage({
                url: data?.redemption_code_setting.image_icon_input_code_popup ?? 'b33ddfbe6b3f93f91e69af3b06e64997',
                isHash: true,
              })}
              alt=""
              layout="fill"
            />
          </div>
          <p className="font-medium mb-4 large:mb-2 text-large">Chúc mừng bạn nhận được {totalChance ?? 0} lượt!</p>
          <p className="mb-2 large:mb-0 max-w-[350px] large:max-w-[410px] whitespace-pre-line text-normal">
            {data?.redemption_code_setting.text_description_input_code_popup}
          </p>
        </div>
      </GeneralDialog>
      {isOpen && isOpenProtectLayer && <div className="absolute top-0 left-0 h-full w-full bg-transparent"></div>}
    </>
  )
}

export default memo(ClaimPrizeDialog)
